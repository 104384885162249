.image {
  width: 70px;
  object-fit: cover;
  border-radius: 60px;
}
.icon {
  color: red;
  border-radius: 50%;
  width: 15px;
  object-fit: cover;
  font-size: 20px;
}
.bottomCart {
  background-color: white;
  box-shadow: 0px 1px 10px #00000078;
  position: fixed;
  height: auto;
  top: 0;
  width: 100%;
}
.setCustomerDialog,
.setWaiterDialog {
  background-color: lightcyan;
}
@media screen and (max-width: 600px) {
  .productList {
    margin-top: 90px;
  }
}
