.switch_account_title {
  font-size: 14px;
  color: black;
}
.switch_account {
  border-radius: 12px;
  padding: 12px 0px 0px 0px;
}
.restrodiv {
  border-radius: 12px;
  padding: 6px;
}
