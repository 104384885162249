$primary: #bc010d;
$secondary: #9c161f;
$info: #5e5b5bd9;
$light: #fefffe;
$dark: #0c0f15;
$danger: #ff4136;
$success: green;
$font-family-base: "Heebo", sans-serif;

$body-bg: $light;

$body-color: #050608;

$headings-color: $dark;

$headings-font-weight: 700;

$enable-responsive-font-sizes: true;

$border-radius: 5px;

$link-decoration: none;

$enable-negative-margins: true;

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "secondary": $secondary,
  "info": $info,
  "success": $success,
);

$table-ongoing: #f9cf28;
$table-available: #058240;
$table-reserved: #009dffda;
$table-closed: #696969;
// dark varient
$table-ongoing-dark: #e8bc0a;
$table-available-dark: #027939;
$table-reserved-dark: #036fb3e2;
$table-closed-dark: #696969d3;

$table-color: #181c25;

.Ongoing {
  background-color: $table-ongoing;
}

.Available {
  background-color: $table-available;
}
.Reserved {
  background-color: $table-reserved;
}
.Closed {
  background-color: $table-closed;
}

.table-ongoing {
  background-color: $table-ongoing;
}
.table-available {
  background-color: $table-available;
  color: white;
}
.table-reserved {
  background-color: $table-reserved;
  color: $light;
}
.table-closed {
  background-color: $table-closed;
  color: $light;
}
.table-ongoing:hover {
  background-color: $table-ongoing-dark;
}
.table-available:hover {
  background-color: $table-available-dark;
}
.table-reserved:hover {
  background-color: $table-reserved-dark;
}
.table-closed:hover {
  background-color: $table-closed-dark;
}
.table-create {
  background-color: #856c0b;
}
@import "~bootstrap/scss/bootstrap";

/********** Template CSS **********/
:root {
  --primary: #009cff;
  --light: #f3f6f9;
  --dark: #191c24;
  --bs-table-color: $table-color;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  transition: 0.5s;
}

.btn.btn-primary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 50px;
}

/*** Layout ***/
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 100vh;
  overflow-y: auto;
  border-right: 2px solid var(--light);
  // transition: 0.2s;
  z-index: 999;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sidebar::-webkit-scrollbar {
  display: none;
}
.sidebarClose {
  margin-left: 0px;
  top: 0;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 100vh;
  overflow-y: auto;
  background: #f3f6f9;
  border-right: 2px solid var(--light);
  transition: 0.2s;
  z-index: 999;
  position: sticky;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sidebarClose::--webkit-scrollbar {
  display: none;
}

.content {
  margin-left: 200px;
  min-height: 100vh;
  background: #ffffff;
  // transition: 0.2s;
}

@media screen and (max-width: 600px) {
  .sidebarClose {
    margin-top: 0 !important;
  }
}

@media screen and (max-width: 990px) {
  .sidebarClose {
    position: fixed;
    margin-top: 50px;
    opacity: 1 !important;
    width: 100%;
  }
}

@media (min-width: 992px) {
  .sidebar {
    margin-left: 0;
  }

  .sidebar.open {
    margin-left: -200px;
  }

  .content {
    width: calc(100% - 200px);
  }

  .content.open {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  .sidebar {
    margin-left: -200px;
  }

  .sidebar.open {
    margin-left: 0;
    padding-top: 40px;
  }

  .content {
    width: 100%;
    margin-left: 0;
  }
}

/*** Navbar ***/
.sidebar .navbar .navbar-nav .nav-link {
  padding: 6px 0px 6px 8px;
  color: var(--light);
  font-weight: 500;
  outline: none;
  margin: 1px 0px;
  // border-left: 2px solid var(--light);
}
.sidebarClose .navbar .navbar-nav .nav-link {
  color: #f3f6f9;
}
.sidebarClose .navbar .navbar-nav .nav-link {
  width: 100%;
  text-align-last: center;
  border-left: 2px solid rgba(255, 255, 255, 0);
}
.sidebarClose .navbar .navbar-nav .nav-link:hover {
  color: $primary;
  background-color: #f3f6f9;
  // border-left: 2px solid $info;
}
.sidebarClose .navbar .navbar-nav .nav-link:active {
  background-color: #f3f6f9;
  color: $primary;
  // border-left: 2px solid $info;
}
.sidebar .navbar .navbar-nav .nav-link:hover {
  background: #f3f6f9;
  border-left: 2px solid $info;
  color: $primary;
}
.sidebar .navbar .navbar-nav .nav-link.active {
  color: var(--danger);
  background: #dbdfe2;
  border-color: $danger;
  border-left: 2px solid $info;
}

.sidebar .navbar .navbar-nav .nav-link i {
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
}

.sidebar .navbar .dropdown-toggle::after {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}

.sidebar .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

.sidebar .navbar .dropdown-item {
  padding-left: 25px;
  border-radius: 0 30px 30px 0;
}

.content .navbar .navbar-nav .nav-link {
  margin-left: 25px;
  padding: 12px 0;
  color: var(--dark);
  outline: none;
}

.content .navbar .navbar-nav .nav-link:hover,
.content .navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.content .navbar .sidebar-toggler,
.content .navbar .navbar-nav .nav-link i {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 40px;
}

.content .navbar .dropdown-toggle::after {
  margin-left: 6px;
  vertical-align: middle;
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  transition: 0.5s;
}

.content .navbar .dropdown-toggle[aria-expanded="true"]::after {
  transform: rotate(-180deg);
}

@media (max-width: 575.98px) {
  .content .navbar .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

/*** Date Picker ***/
.bootstrap-datetimepicker-widget.bottom {
  top: auto !important;
}

.bootstrap-datetimepicker-widget .table * {
  border-bottom-width: 0px;
}

.bootstrap-datetimepicker-widget .table th {
  font-weight: 500;
}

.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 10px;
  border-radius: 2px;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
  background: var(--primary);
}

.bootstrap-datetimepicker-widget table td.today::before {
  border-bottom-color: var(--primary);
}

/*** Testimonial ***/
.progress .progress-bar {
  width: 0px;
  transition: 2s;
}

/*** Testimonial ***/
.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 5px solid var(--primary);
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--dark);
  border-color: var(--primary);
}

.bg-light {
  background-color: #f3f6f9 !important;
}

.bg-success {
  background-color: #198754 !important;
}

.form-control {
  color: black;
}

.form-control:focus {
  color: black;
}

img {
  object-fit: cover;
}

a.pointer {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.icon-gold {
  color: goldenrod;
}

@media (max-width: 978px) {
  .container-fluid {
    padding: 12 !important;
    margin: 0 !important;
  }

  .hidden-sm {
    display: none;
  }
}
@media (max-width: 425px) {
  .pagination {
    text-align: center;
    display: block;
  }
}

th {
  color: #687182;
  font-weight: 500;
}
thead {
  background: var(--light) !important;
}
tr {
  border-bottom: 1px solid #f1f3f5;
}

// tabs design
.nav {
  color: #050608;
}
.nav-tabs .nav-link {
  border: 1px solid transparent !important ;
  color: #ba6868;
}
.nav-tabs .nav-link.active {
  border-bottom: 2px solid $primary !important ;
  color: $primary;
  font-weight: 500;
}
.offcanvas-end {
  width: 100% !important;
}

.kot-accordion .accordion-button {
  font-size: 0.9rem;
  padding: 1em;
}

.kot-accordion .accordion-body {
  padding: 1em;
  font-size: 0.9rem;
}

.kot-accordion .btn {
  font-size: 0.9rem;
}

// @media only screen and (max-width: 600px) {
//   .kot-accordion .accordion-button::after {
//     content: none;
//   }

//   .kot-accordion .accordion-button {
//     font-size: 0.9rem;
//     padding: 1em;
//   }

//   .kot-accordion .accordion-body {
//     padding: 1em;
//     font-size: 0.9rem;
//   }

//   .kot-accordion .btn {
//     font-size: 0.9rem;
//   }
// }

.bottom-tab-nav {
  border-top: solid 1px #ebebeb;
}
.nav-link,
.bottom-nav-link {
  color: $info;
}
.bottom-nav-link.active {
  color: $primary;
}
.bottom-tab-label {
  font-size: 0.7rem;
  margin-top: 4px;
}

@media screen and (max-width: 600px) {
  .main-container {
    margin-bottom: 80px;
  }
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  padding: 0px !important;
  margin: 0.5em;
}

.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 30px !important;
}

.toggle-table-header button,
.toggle-table-header button:hover {
  background-color: transparent !important;
  color: red !important;
  border: 0px;
  padding: 0;
  font-weight: 500;
}

.expense {
  .form-control {
    &.hide-border {
      border: none;
      &:focus {
        border: none;
        border-color: none;
      }
    }
  }
}

.icon-primary {
  color: rgba(0, 0, 0, 0.4117647059);
}

.icon-info {
  color: #ace618;
}

.bg-warning {
  color: white;
  background-color: darkorange;
}
