.button1 {
  color: #ffff;
}

.button2 {
  border: 1px solid #d31823;
  border-radius: 5px;
  size: 50px;
  font-weight: bold;
  color: #d31823;
}
.button2:hover {
  box-shadow: 3px 3px 5px rgb(160, 164, 165);
  background-color: #d31823;
  color: #fff;
}
.gray {
  color: gray;
}
.gray-bold {
  color: gray;
  font-weight: bold;
}

.green-border {
  border: 2px solid green;
  animation: blink 2s;
  animation-iteration-count: 5;
}
@keyframes blink {
  50% {
    border-color: #fa8322;
  }
}
.green-color {
  color: green;
}

.select-account-role-dialog.bs-popover-end > .popover-arrow::after {
  border-right-color: var(--bs-popover-header-bg);
}

.const-height {
  height: 400px;
}

.mobileMenuToggler {
  display: none;
}
@media screen and (max-width: 990px) {
  .mobileMenuToggler {
    display: block;
  }
  .phUserName {
    display: none;
  }
}
